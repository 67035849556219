import {Toaster as Sonner, toast} from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({...props}: ToasterProps) => {
  return <Sonner {...props} />;
};

export {Toaster, toast};

export default Toaster;
