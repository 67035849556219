'use client';

import {UserIcon} from '@heroicons/react/24/solid';
import * as RadixAvatar from '@radix-ui/react-avatar';

interface AvatarProps {
  src?: string | null;
  alt?: string;
  className?: string;
  fallback?: string;
  fallbackClassName?: string;
  onClick?: () => void;
}

const Avatar = ({
  src,
  alt,
  className,
  fallback,
  fallbackClassName,
  onClick,
}: AvatarProps) => {
  const fallbackInitials = fallback
    ?.split(' ')
    .map(name => name.charAt(0).toUpperCase())
    .join(' ');

  return (
    <RadixAvatar.Root
      onClick={onClick}
      className={`inline-flex select-none items-center overflow-hidden dark:bg-white/15 ${className}`}
    >
      <RadixAvatar.Image
        className="AvatarImage h-full w-full object-cover"
        src={src || undefined}
        alt={alt}
      />
      <RadixAvatar.Fallback
        className={`flex h-full w-full items-center justify-center bg-white/10 p-1 text-neutral-500 ${fallbackClassName}`}
        delayMs={src ? 500 : undefined}
      >
        {!fallback ? (
          <UserIcon width={'100%'} className="fill-black/50" />
        ) : (
          <p>{fallbackInitials}</p>
        )}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
};

export default Avatar;
