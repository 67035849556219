import {Penalty} from 'db/schema/operation';

export const defaultCommission = 11;

export const maxShippingHours = 96;
export const shippingExtensionHours = 96;

export const PENALTY_VALUES: {
  [key in Penalty['violation']]: number;
} = {
  COUNTERFEIT_PRODUCT: 0,
  FAILURE_TO_SHIP: 0.4,
  PROHIBITED_PRODUCT: 0,
  QUALITY_CONTROL: 0,
};
