import {add} from 'date-fns/add';
import {Order, StoreOrder} from 'db/schema/order';
import {Product} from 'db/schema/product';
import {maxShippingHours} from '../constant';

export const formatCurrency = (value?: number) =>
  (value || 0)
    .toLocaleString('en', {
      currency: 'NGN',
      style: 'currency',
      compactDisplay: 'short',
    })
    .replace(/\.00/g, '')
    .toString();

export const cleanObject = (object?: {[key: string]: string | undefined}) => {
  if (object)
    Object.keys(object).forEach(key => {
      if (object[key] === null || object[key] === undefined) {
        delete object[key];
      }
    });
  return object;
};

export const filterNulls = <S>(e: S | null): e is Exclude<typeof e, null> =>
  e !== null;
export const filterUndefined = <S>(
  e: S | undefined
): e is Exclude<typeof e, undefined> => e !== undefined;

export const removeUndefined = (obj: {[key: string]: any}) => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  return obj;
};

export const getVariant = (
  variants: Product['variants'],
  variantId?: number | null
) =>
  (variantId
    ? variants.find(x => x.id === variantId)
    : variants.sort((a, b) => a.price - b.price)[0])!;

export const getThumbnail = (
  images: Product['images'],
  variantColor?: string
) => {
  return (
    (images.find(x => x.id === variantColor || x.id === '0') || images?.[0])
      ?.url || ''
  );
};

export const uniqueOrThrow = <T>(value: T | undefined): T => {
  if (!value) throw new Error('Found non unique or inexistent value');
  return value;
};

export const oneOrThrow = <T>(values: T[]): T => {
  const value = values?.[0];
  if (!value) throw new Error('Found non unique or inexistent value');
  return value;
};

export const notNull = <T>(value: T | null | undefined): T => {
  if (!value) throw new Error('Null value');
  return value;
};

export const getShipmentDeadline = (
  storeOrder: StoreOrder & {
    order: Order;
  }
) =>
  storeOrder.shippingExtendedTo ||
  (storeOrder.order.paymentConfirmedAt
    ? add(storeOrder.order.paymentConfirmedAt, {
        hours: maxShippingHours,
      })
    : null);

export const upperFirst = (value: string | undefined) =>
  typeof value !== 'string'
    ? ''
    : value.charAt(0).toUpperCase() + value.slice(1);

export const formatEnum = (str: string) =>
  upperFirst(str.split('_').join(' ').toLowerCase());
