import {useEffect, useState} from 'react';
import {useRouter} from 'next/navigation';
import {useStore} from 'c/store/store';
import {StoreWithFollowers} from 'db/schema/store';

interface FollowProps {
  userId: number | undefined;
  store: StoreWithFollowers;
  className?: string;
  onToggle: () => void;
}

export function Follow({userId, store, className, onToggle}: FollowProps) {
  const router = useRouter();
  const onAfterAuth = useStore(state => state.onAfterAuth);

  const following = userId
    ? store.followers.some(x => x.customerId === userId)
    : false;

  return (
    <>
      <button
        disabled={following}
        className={className || 'border-b px-2 py-1 text-sm text-white'}
        onClick={() => {
          if (userId) onToggle();
          else {
            onAfterAuth(() => onToggle());
            router.push('/login');
          }
        }}
      >
        {following ? 'Following' : 'Follow'}
      </button>
    </>
  );
}
