import {SVGProps} from 'react';

export const ShareSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2475 10.9998C22.25 10.5746 22.1429 10.156 21.9364 9.78436C21.73 9.41271 21.4311 9.10059 21.0688 8.87817L9.6534 1.43488C9.03591 0.966331 8.29986 0.679483 7.52814 0.606644C6.75643 0.533805 5.97971 0.677868 5.28545 1.02261C4.59118 1.36735 4.00696 1.89908 3.59857 2.55791C3.19017 3.21674 2.97383 3.97651 2.9739 4.75165L2.97449 6.77601C2.9745 6.88559 2.95291 6.9941 2.91094 7.09533C2.86898 7.19656 2.80746 7.28851 2.72991 7.36594L0.862906 9.23294C0.6017 9.49675 0.403287 9.81602 0.282411 10.167L0.273571 10.1665V10.1971C0.0990597 10.717 0.100303 11.2798 0.277107 11.7989V11.8295L0.285948 11.829C0.406824 12.18 0.605236 12.4992 0.866441 12.7631L2.73345 14.6301C2.88978 14.7861 2.97775 14.9979 2.97803 15.2188L2.97802 17.2426C2.97502 17.7884 3.08007 18.3294 3.28711 18.8344C3.49416 19.3394 3.79911 19.7985 4.18439 20.1851C4.50642 20.5063 4.8784 20.7732 5.28585 20.9754C5.96963 21.3211 6.73641 21.4689 7.4997 21.4021C8.26299 21.3352 8.9924 21.0564 9.60567 20.5971L21.0446 13.1361C21.4131 12.9148 21.7177 12.6015 21.9286 12.227C22.1394 11.8525 22.2493 11.4296 22.2475 10.9998ZM4.64112 6.77837L4.63994 4.75342C4.63426 4.28731 4.76166 3.82924 5.00717 3.43299C5.25269 3.03673 5.60615 2.71873 6.02606 2.51631C6.45071 2.30251 6.92784 2.21518 7.4007 2.2647C7.87355 2.31422 8.32223 2.49851 8.69338 2.79566L19.9944 10.1659L2.28379 10.167L3.9074 8.54343C4.14029 8.31232 4.32505 8.03733 4.45099 7.73436C4.57693 7.4314 4.64156 7.10647 4.64112 6.77837ZM8.64918 19.2375C8.27842 19.5177 7.83689 19.6891 7.37414 19.7324C6.91138 19.7756 6.44574 19.689 6.02946 19.4823C5.61319 19.2756 5.26276 18.957 5.01753 18.5622C4.77229 18.1674 4.64194 17.7121 4.64112 17.2473V15.22C4.64202 14.8919 4.57785 14.5668 4.45231 14.2636C4.32677 13.9605 4.14237 13.6852 3.90976 13.4538L2.28615 11.8301L19.995 11.8331L8.64918 19.2375Z"
      fill="white"
    />
  </svg>
);

export const PlaySvg = () => (
  <svg
    width="62"
    height="71"
    viewBox="0 0 62 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_877_639)">
      <path
        d="M55 23.3398C61.6667 27.1888 61.6667 36.8113 55 40.6603L17.5 62.3109C10.8333 66.1599 2.5 61.3486 2.5 53.6506V10.3493C2.5 2.65134 10.8333 -2.15989 17.5 1.68911L55 23.3398Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_877_639"
        x="0.5"
        y="0.334473"
        width="61.5"
        height="70.3311"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_877_639"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_877_639"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const PauseSvg = () => (
  <svg
    width="44"
    height="63"
    viewBox="0 0 44 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_880_753)">
      <rect x="1" width="10" height="58" rx="5" fill="white" />
    </g>
    <g filter="url(#filter1_d_880_753)">
      <rect x="31" width="10" height="58" rx="5" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_d_880_753"
        x="0"
        y="0"
        width="14"
        height="63"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="3" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_880_753"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_880_753"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_880_753"
        x="30"
        y="0"
        width="14"
        height="63"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="3" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_880_753"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_880_753"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const VolumeSvg = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_878_660)">
      <path
        d="M13 2.68314C12.8808 2.57687 12.7254 2.52027 12.5658 2.52496C12.4061 2.52965 12.2543 2.59528 12.1416 2.70837C12.0288 2.82147 11.9636 2.97344 11.9593 3.1331C11.9551 3.29275 12.0121 3.44797 12.1188 3.56689C13.1602 4.61094 13.745 6.02538 13.745 7.50002C13.745 8.97465 13.1602 10.3891 12.1188 11.4331C12.0553 11.4897 12.004 11.5586 11.9681 11.6357C11.9322 11.7128 11.9124 11.7963 11.9099 11.8813C11.9074 11.9663 11.9223 12.0509 11.9536 12.1299C11.9849 12.209 12.032 12.2808 12.0921 12.341C12.1521 12.4012 12.2238 12.4485 12.3027 12.4801C12.3817 12.5116 12.4662 12.5267 12.5512 12.5245C12.6362 12.5222 12.7199 12.5026 12.797 12.4669C12.8742 12.4313 12.9433 12.3802 13 12.3169C14.2757 11.0384 14.9922 9.3061 14.9922 7.50002C14.9922 5.69393 14.2757 3.9616 13 2.68314Z"
        fill="white"
      />
      <path
        d="M11.3081 4.55811C11.2505 4.49842 11.1815 4.45081 11.1053 4.41805C11.029 4.3853 10.947 4.36805 10.864 4.36733C10.781 4.36661 10.6987 4.38243 10.6219 4.41385C10.5451 4.44528 10.4753 4.49168 10.4166 4.55037C10.358 4.60905 10.3115 4.67883 10.2801 4.75564C10.2487 4.83245 10.2329 4.91475 10.2336 4.99774C10.2343 5.08073 10.2516 5.16274 10.2843 5.23899C10.3171 5.31524 10.3647 5.38421 10.4244 5.44186C10.9692 5.98828 11.2751 6.7284 11.2751 7.49999C11.2751 8.27158 10.9692 9.0117 10.4244 9.55812C10.3647 9.61577 10.3171 9.68473 10.2843 9.76099C10.2516 9.83724 10.2343 9.91925 10.2336 10.0022C10.2329 10.0852 10.2487 10.1675 10.2801 10.2443C10.3115 10.3211 10.358 10.3909 10.4166 10.4496C10.4753 10.5083 10.5451 10.5547 10.6219 10.5861C10.6987 10.6176 10.781 10.6334 10.864 10.6326C10.947 10.6319 11.029 10.6147 11.1053 10.5819C11.1815 10.5492 11.2505 10.5016 11.3081 10.4419C12.0871 9.66096 12.5246 8.60299 12.5246 7.49999C12.5246 6.39699 12.0871 5.33902 11.3081 4.55811Z"
        fill="white"
      />
      <path
        d="M8.63688 0.129372C6.73783 0.485825 5.04831 1.55825 3.9175 3.125H3.125C2.2965 3.12599 1.50222 3.45555 0.916387 4.04138C0.330551 4.62722 0.000992411 5.4215 0 6.25L0 8.75C0.000992411 9.57849 0.330551 10.3728 0.916387 10.9586C1.50222 11.5444 2.2965 11.874 3.125 11.875H3.91813C5.04861 13.4418 6.73796 14.5143 8.63688 14.8706C8.72699 14.8872 8.81966 14.8838 8.9083 14.8606C8.99695 14.8374 9.07941 14.7949 9.14984 14.7363C9.22026 14.6777 9.27694 14.6043 9.31585 14.5213C9.35476 14.4384 9.37496 14.3479 9.375 14.2562V0.743747C9.37496 0.652114 9.35476 0.561612 9.31585 0.47865C9.27694 0.395689 9.22026 0.322299 9.14984 0.263677C9.07941 0.205056 8.99695 0.162639 8.9083 0.139429C8.81966 0.11622 8.72699 0.112787 8.63688 0.129372Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_878_660">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const VolumeMutedSvg = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="10.7869"
      y="9.85651"
      width="10"
      height="1.69779"
      transform="rotate(-42.8513 10.7869 9.85651)"
      fill="#D9D9D9"
    />
    <rect
      x="18.4977"
      y="11.1703"
      width="10"
      height="1.69779"
      transform="rotate(-136.102 18.4977 11.1703)"
      fill="#D9D9D9"
    />
    <path
      d="M8.63688 0.0103231C6.73783 0.366776 5.04831 1.4392 3.9175 3.00595H3.125C2.2965 3.00694 1.50222 3.3365 0.916387 3.92234C0.330551 4.50817 0.000992411 5.30245 0 6.13095L0 8.63095C0.000992411 9.45944 0.330551 10.2537 0.916387 10.8396C1.50222 11.4254 2.2965 11.755 3.125 11.7559H3.91813C5.04861 13.3228 6.73796 14.3952 8.63688 14.7516C8.72699 14.7682 8.81966 14.7647 8.9083 14.7415C8.99695 14.7183 9.07941 14.6759 9.14984 14.6173C9.22026 14.5586 9.27694 14.4853 9.31585 14.4023C9.35476 14.3193 9.37496 14.2288 9.375 14.1372V0.624698C9.37496 0.533065 9.35476 0.442562 9.31585 0.359601C9.27694 0.27664 9.22026 0.203249 9.14984 0.144628C9.07941 0.0860074 8.99695 0.0435898 8.9083 0.0203804C8.81966 -0.00282901 8.72699 -0.00626252 8.63688 0.0103231Z"
      fill="white"
    />
  </svg>
);
