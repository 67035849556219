import {SVGProps} from 'react';

export const HeartSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
    />
  </svg>
);

export const ChevronLeftSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.43066 1.42905L2.84495 6.01335L7.41923 10.609L6.00748 12.0173L0.0149507 6.00983L6.02241 0.0172973L7.43066 1.42905Z"
      fill="white"
    />
  </svg>
);

export const ChevronRightSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <path
      d="M8.60498 16.6049L13.185 12.0149L8.60498 7.42489L10.015 6.01489L16.015 12.0149L10.015 18.0149L8.60498 16.6049Z"
      fill="#C5C5C5"
    />
  </svg>
);

export const TrashSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.5 5.83334L17.5 5.83334"
      stroke="#C5C5C5"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M4.16699 5.83334L4.86765 15.6425C4.9424 16.6891 5.81329 17.5 6.86256 17.5H13.1381C14.1874 17.5 15.0582 16.6891 15.133 15.6425L15.8337 5.83334"
      stroke="#C5C5C5"
      strokeWidth="2"
    />
    <path
      d="M5.83301 5.83333L6.47698 3.25746C6.58827 2.8123 6.98825 2.5 7.44712 2.5H12.5522C13.0111 2.5 13.4111 2.8123 13.5224 3.25746L14.1663 5.83333"
      stroke="#C5C5C5"
      strokeWidth="2"
    />
    <path
      d="M8.33301 9.16666V14.1667"
      stroke="#C5C5C5"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M11.667 9.16666V14.1667"
      stroke="#C5C5C5"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const EditSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="#C5C5C5"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.349 0.325196L17.6711 3.60938C18.1096 4.04298 18.1096 4.75197 17.6711 5.18556L15.8337 7.00197L10.9173 2.14161L12.7547 0.325196C13.1933 -0.108399 13.9104 -0.108399 14.349 0.325196ZM10.3987 7.64504V7.6465C10.535 7.78127 10.6032 7.95998 10.6032 8.13576C10.6032 8.31155 10.535 8.48879 10.3987 8.62502V8.62649L10.3972 8.62795L5.94021 13.0357H5.93872C5.9002 13.0738 5.85723 13.106 5.81426 13.1338H5.81278C5.76832 13.1617 5.72239 13.1836 5.67498 13.1997C5.42901 13.2847 5.14303 13.229 4.94745 13.0357H4.94597V13.0342C4.90744 12.9961 4.87484 12.9536 4.84669 12.9112V12.9097C4.81854 12.8658 4.79631 12.8203 4.78001 12.7735C4.69407 12.5303 4.75038 12.2476 4.94448 12.0542V12.0528L9.40301 7.64504H9.40449C9.54081 7.51027 9.72158 7.44289 9.89939 7.44289C10.0772 7.44289 10.2565 7.51027 10.3943 7.64504H10.3987ZM6.73886 15.983C5.40826 16.3902 4.07915 16.7959 2.74856 17.2032C-0.377889 18.1597 -0.364553 18.7969 0.488923 15.8204L1.83285 11.1328L1.82841 11.1284L9.98681 3.06153L14.9032 7.9219L6.7433 15.9888L6.73886 15.983ZM2.67151 11.9619L5.9002 15.1538C5.02598 15.4205 4.15176 15.6871 3.27902 15.9522C1.22534 16.5777 1.23423 16.9966 1.79284 15.0411L2.67151 11.9619Z"
      />
    </svg>
  );
};

export const CopySvg = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4375 11.875H3.4375C2.52612 11.874 1.65236 11.5115 1.00792 10.8671C0.363476 10.2226 0.000992606 9.34888 0 8.4375L0 3.4375C0.000992606 2.52612 0.363476 1.65236 1.00792 1.00792C1.65236 0.363476 2.52612 0.000992606 3.4375 0L8.4375 0C9.34888 0.000992606 10.2226 0.363476 10.8671 1.00792C11.5115 1.65236 11.874 2.52612 11.875 3.4375V8.4375C11.874 9.34888 11.5115 10.2226 10.8671 10.8671C10.2226 11.5115 9.34888 11.874 8.4375 11.875ZM3.4375 1.875C3.0231 1.875 2.62567 2.03962 2.33265 2.33265C2.03962 2.62567 1.875 3.0231 1.875 3.4375V8.4375C1.875 8.8519 2.03962 9.24933 2.33265 9.54235C2.62567 9.83538 3.0231 10 3.4375 10H8.4375C8.8519 10 9.24933 9.83538 9.54235 9.54235C9.83538 9.24933 10 8.8519 10 8.4375V3.4375C10 3.0231 9.83538 2.62567 9.54235 2.33265C9.24933 2.03962 8.8519 1.875 8.4375 1.875H3.4375ZM15 11.5625V4.375C15 4.12636 14.9012 3.8879 14.7254 3.71209C14.5496 3.53627 14.3111 3.4375 14.0625 3.4375C13.8139 3.4375 13.5754 3.53627 13.3996 3.71209C13.2238 3.8879 13.125 4.12636 13.125 4.375V11.5625C13.125 11.9769 12.9604 12.3743 12.6674 12.6674C12.3743 12.9604 11.9769 13.125 11.5625 13.125H4.375C4.12636 13.125 3.8879 13.2238 3.71209 13.3996C3.53627 13.5754 3.4375 13.8139 3.4375 14.0625C3.4375 14.3111 3.53627 14.5496 3.71209 14.7254C3.8879 14.9012 4.12636 15 4.375 15H11.5625C12.4739 14.999 13.3476 14.6365 13.9921 13.9921C14.6365 13.3476 14.999 12.4739 15 11.5625Z"
      fill="white"
    />
  </svg>
);

export const HeartEmojiSvg = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.92952 1.8204C0.832829 2.91805 0.216797 4.4062 0.216797 5.95783C0.216797 7.50945 0.832829 8.9976 1.92952 10.0952L2.75358 10.9226L10.998 19.1974L19.2419 10.9226L20.0664 10.0952C21.1631 8.9976 21.7791 7.50945 21.7791 5.95783C21.7791 4.4062 21.1631 2.91805 20.0664 1.8204C19.527 1.27567 18.8849 0.843243 18.1774 0.548132C17.4698 0.253021 16.7108 0.101074 15.9442 0.101074C15.1776 0.101074 14.4186 0.253021 13.711 0.548132C13.0035 0.843243 12.3614 1.27567 11.822 1.8204L10.998 2.64775L10.1734 1.8204C9.63402 1.27574 8.99201 0.843374 8.2845 0.548305C7.577 0.253236 6.81804 0.101311 6.05147 0.101311C5.2849 0.101311 4.52594 0.253236 3.81843 0.548305C3.11093 0.843374 2.46893 1.27574 1.92952 1.8204Z"
      fill="#FF143A"
    />
  </svg>
);
